<template>
  <v-dialog v-model="dialogVisible" width="400" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn text v-bind="$attrs" v-on="on"
        ><v-icon left>mdi-account</v-icon> Personen hinzufügen</v-btn
      >
    </template>

    <v-card>
      <v-system-bar
        >Person(en) auswählen<v-spacer /><v-btn icon @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >
      <v-toolbar flat>
        <PersonInput
          v-model="pickerValue"
          label="Person suchen"
          group="person"
          clearable
          hide-details
          @input="add"
      /></v-toolbar>
      <v-divider />
      <v-card-text class="pa-0 ma-0">
        <v-list>
          <v-list-item-group v-model="modelValue" multiple color="primary">
            <PersonItemBasic
              v-for="item in items"
              :key="item.id"
              :value="item"
            />
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="" text @click="close">Abbrechen</v-btn>
        <v-spacer />
        <v-btn
          color="success"
          text
          @click="accept"
          :disabled="modelValue.length == 0"
          >{{
            modelValue.length > 1 ? modelValue.length + " Personen" : "Person"
          }}
          hinzufügen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import PersonItemBasic from "@/components/PersonItem.vue";
import PersonInput from "common/components/PersonInput.vue";

export default {
  name: "CoursePicker",
  components: { PersonInput, PersonItemBasic },
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      modelValue: this.value,
      pickerValue: null,
    };
  },
  watch: {
    modelValue() {
      //console.log('modelValue=', this.modelValue);
    },
  },
  async created() {
    this.items.push(this.$_profilePerson);
  },
  methods: {
    accept() {
      if (this.modelValue) {
        this.$emit("input", this.modelValue);
        for (const value of this.modelValue) {
          this.items = this.items.filter((el) => el.id !== value.id);
        }
        this.close();
      }
    },
    add(item) {
      if (item && !this.items.includes(item)) {
        this.items.unshift(item);
        this.modelValue.push(item);
        window.setTimeout(() => (this.pickerValue = null), 100);
      }
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
